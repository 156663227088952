
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EnterBehavioralSurveyWidget from "@/components/widgets/EnterBehavioralSurvey-Widget.vue";
import EnterDataByTraitsModal from "@/components/modals/wizards/EnterDataByTraitsModal.vue";
import EnterDataByPrintedVersionModal from "@/components/modals/wizards/EnterDataByPrintedVersionModal.vue";

export default defineComponent({
  name: "lda-enter-behavioral-survey",
  components: {
    EnterBehavioralSurveyWidget,
    EnterDataByTraitsModal,
    EnterDataByPrintedVersionModal,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const entryType = ref<string>();
    const licensedDistributorAssocId = computed(() => {
      return store.getters.getLDAssociatesLicensedDistributorId;
    });
    const isLicensedDistAssocIdReady = ref(false);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Enter Survey Results", ["Surveys"]);
    });

    if (licensedDistributorAssocId.value > 0) {
      isLicensedDistAssocIdReady.value = true;
    } else {
      store
        .dispatch(
          Actions.GET_LDASSOCIATES_LICENSED_DISTRIBUTOR_ID,
          currentUser.id
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText:
              "There was a problem retrieving licensed distributor assoc id.",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    function onEntryClick(value) {
      entryType.value = value;
    }

    watch(licensedDistributorAssocId, () => {
      isLicensedDistAssocIdReady.value = true;
    });

    return {
      currentUser,
      entryType,
      licensedDistributorAssocId,
      isLicensedDistAssocIdReady,
      onEntryClick,
    };
  },
});
